import React from 'react';

interface IIfProps {
    children?: any;
    isFalse?: boolean;
    isTrue?: boolean;
}

const If = ({ children, isFalse, isTrue }: IIfProps): JSX.Element => {

    const _check = () => {
        if(isTrue !== undefined) {
            return isTrue;
        }
        else if(isFalse !== undefined) {
            return !isFalse;
        }

        return false;
    };

    const output = _check() ? children : null;

    if(Array.isArray(children)) {
        return (<div className="layout-full-min-height">{output}</div>);
    }

    return output;
};

export default If;