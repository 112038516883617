import React from 'react';
import { format } from 'date-fns';

interface INullableFormattedDateWithoutUtcProps {
    value: Date | undefined;
}

export default function nullableFormattedDateWithoutUtc(props: INullableFormattedDateWithoutUtcProps): JSX.Element {
    const { value } = props;
    let utc_val;
    if(value != null) {
        try {
            utc_val = new Date(value?.getFullYear(), value?.getMonth(), value?.getDate());
        }
        catch {
            const valueDate = new Date(value);
            utc_val = new Date(valueDate?.getFullYear(), valueDate?.getMonth(), valueDate?.getDate());
        }
    }

    return (
        <>
            {utc_val
                ? < span >{format(utc_val, 'dd/MM/yyyy')}</span>
                : ' - '
            }
        </>
    );

}
