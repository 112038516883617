import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import TableUtils from '../../../utils/tableUtils';
import HelptextConstantsEnum from '../../../constants/helptextConstantsEnum';
import DataGridComponent from '../../common/dataGrid';
import TruncatedStringTooltip from '../../common/truncatedStringTooltip';

interface ProvisionListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
    disableLinks?: boolean;
}

const messages = {
    invoiceType: LanguageUtils.createMessage('Invoice Type'),
    accountNo: LanguageUtils.createMessage('Account no'),
    accountName: LanguageUtils.createMessage('Account name'),
    amount: LanguageUtils.createMessage('Amount'),
    currency: LanguageUtils.createMessage('Currency')
};

const ProvisionList = forwardRef((props: ProvisionListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.REPORT_PROVISIONS);
        }
    }, []);

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'invoiceType',
                headerName: intl.formatMessage(messages.invoiceType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (param: GridRenderCellParams) => {
                    return <TruncatedStringTooltip value={applyRowFn(param)?.invoiceType} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('invoiceType', hiddenColumns)
            },
            {
                field: 'accountNumber',
                headerName: `${intl.formatMessage(messages.accountNo)}`,
                flex: 1,
                align: 'right',
                sortable: true,
                renderCell: (param: GridRenderCellParams) => {
                    return <TruncatedStringTooltip value={applyRowFn(param)?.accountNumber} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('accountNumber', hiddenColumns)
            },
            {
                field: 'accountName',
                headerName: intl.formatMessage(messages.accountName),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.accountName} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('accountName', hiddenColumns)
            },
            {
                field: 'amount',
                headerName: intl.formatMessage(messages.amount),
                flex: 0.3,
                align: 'right',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.amount} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('amount', hiddenColumns)
            },
            {
                field: 'currency',
                headerName: intl.formatMessage(messages.currency),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.currency?.code} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('currency', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.customerNoName + row?.amount + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default ProvisionList;
