import React, { useEffect } from 'react';
import { FormControl, Button, Autocomplete, Grid, TextField } from '@mui/material';
import OrderFilters from '../../interfaces/filters/orderFilters';
import Customer from '../../interfaces/output/customer';
import UnitGroup from '../../interfaces/output/unitGroup';
import CustomerFilters from '../../interfaces/output/filters';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import LanguageUtils from '../../utils/LanguageUtils';
import { fetchUnitGroups, getUnitGroups } from '../../reducers/unitGroupReducer';
import PageUtils from '../../utils/pageUtils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomers, getCustomers, isLoadingCustomers, resetCustomers } from '../../reducers/customerReducer';
import { FormattedMessage, useIntl } from 'react-intl';
import ErrorType from '../../interfaces/output/errorType';
import { fetchErrorTypes, getErrorTypes } from '../../reducers/errorTypeReducer';
import InvoiceType from '../../interfaces/output/invoiceType';
import { fetchInvoiceTypes, getInvoiceTypes } from '../../reducers/invoiceTypeReducer';
import OrderStatus from '../../interfaces/output/orderStatus';
import { fetchOrderStatuses, getOrderStatuses } from '../../reducers/orderStatusReducer';
import TableUtils from '../../utils/tableUtils';
import GenericTextField from '../common/widgets/genericTextField';
import GenericDatePicker from '../common/widgets/genericDatePicker';
import Terminal from '../../interfaces/output/terminal';
import { fetchTerminals, getTerminals } from '../../reducers/terminalReducer';

interface IFilterProps {
    filters: OrderFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    hiddenColumns?: Array<string>;
}

const messages = {
    unitGroup: LanguageUtils.createMessage('Unit group'),
    gtsCustomerNo: LanguageUtils.createMessage('GTS customer number'),
    customer: LanguageUtils.createMessage('Customer name'),
    orderNo: LanguageUtils.createMessage('Order number'),
    status: LanguageUtils.createMessage('Status'),
    invoiceType: LanguageUtils.createMessage('Invoice type'),
    validFromDate: LanguageUtils.createMessage('Valid from'),
    validToDate: LanguageUtils.createMessage('Valid to'),
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    errorType: LanguageUtils.createMessage('Error type'),
    showOnlyOrdersWithErrors: LanguageUtils.createMessage('Show only orders with errors'),
    showAll: LanguageUtils.createMessage('Show all'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters'),
    errorTypeSelection: LanguageUtils.createMessage('Error type selection')

};

const showOrders = [{
    id: 0,
    value: false,
    label: <FormattedMessage {...messages.showAll} />
},
{
    id: 1,
    value: true,
    label: <FormattedMessage {...messages.showOnlyOrdersWithErrors} />

}];

export default function OrdersFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, hiddenColumns, setDefaultState } = props;
    const [inputValue, setInputValue] = React.useState('');
    const [value, setGTSValue] = React.useState<any>(null);
    const [customerValue, setCustomerValue] = React.useState<any>(null);
    const [inputValueGts, setInputValueGts] = React.useState('');
    const customerFilters = {
        name: inputValue,
        gtsId: inputValueGts
    } as CustomerFilters;
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const unitGroups = useSelector(getUnitGroups).content;
    const customers = useSelector(getCustomers).content;
    const errorTypes = useSelector(getErrorTypes).content;
    const invoiceTypes = useSelector(getInvoiceTypes).content;
    const orderStatuses = useSelector(getOrderStatuses).content;
    const terminals = useSelector(getTerminals).content;
    const propsIsLoadingCustomers = useSelector(isLoadingCustomers);
    const { formatMessage } = useIntl();

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchErrorTypes({ paging }));
        dispatch(fetchInvoiceTypes({ paging }));
        dispatch(fetchOrderStatuses({ paging }));
        dispatch(fetchTerminals({ paging }));
    }, [dispatch]);

    const setDefaultStateOrders = () => {
        setInputValueGts('');
        onChangOrders('gtsId', null, false);
        setInputValue('');
        onChangOrders('customerId', null);
        dispatch(resetCustomers());
        setDefaultState();
    };

    const onChangOrders = (attribute: string, value: any, isForCustomerId = true) => {

        onChange(attribute, value?.id);
        if(isForCustomerId) {
            setCustomerValue(value);
        }
        else {
            setGTSValue(value);
        }

    };

    return (
        <Grid container pr={2} pl={2} direction="column">
            <Grid item>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="orderNumber"
                                label={messages.orderNo}
                                onChange={onChange}
                                value={filters.orderNumber}
                                type="number"
                            />
                        </FormControl>
                    </Grid>
                    {!TableUtils.hideColumn('customerNumber', hiddenColumns) &&
                        <Grid item xs>
                            <Autocomplete
                                value={value}
                                onChange={(e, newValue: any) => {
                                    onChangOrders('customerId', newValue, false);
                                }}
                                id="controllable-states-demo"
                                getOptionLabel={(option: Customer) => option.gtsId}
                                filterSelectedOptions
                                inputValue={inputValueGts}
                                onInputChange={(event, newInputValue: any) => {
                                    setInputValueGts(newInputValue);
                                    if(newInputValue.length > 2) {
                                        dispatch(resetCustomers());
                                        dispatch(fetchCustomers({
                                            paging,
                                            filters: {
                                                ...customerFilters,
                                                gtsId: newInputValue
                                            },
                                            throttle: true
                                        }));
                                    }
                                }}
                                filterOptions={(x) => x}
                                options={customers}
                                loading={propsIsLoadingCustomers}
                                renderInput={(params) => <TextField {...params} label={formatMessage(messages.gtsCustomerNo)} variant="standard" />}
                            />
                        </Grid>
                    }
                    {!TableUtils.hideColumn('customer', hiddenColumns) &&
                        <Grid item xs>
                            <Autocomplete
                                value={customerValue}
                                onChange={(e, newValue: any) => {
                                    onChangOrders('customerId', newValue);
                                }}
                                id="controllable-states-demo"
                                getOptionLabel={(option: Customer) => option.name}
                                filterSelectedOptions
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue: any, reason) => {
                                    setInputValue(newInputValue);
                                    if(newInputValue.length > 2) {
                                        dispatch(resetCustomers());
                                        dispatch(fetchCustomers({
                                            paging,
                                            filters: {
                                                ...customerFilters,
                                                name: newInputValue
                                            },
                                            throttle: true
                                        }));
                                    }
                                    if(reason === 'clear' && event.type === 'click') {
                                        dispatch(resetCustomers());
                                    }
                                }}
                                filterOptions={(x) => x}
                                options={customers}
                                loading={propsIsLoadingCustomers}
                                renderInput={(params) => <TextField {...params} label={formatMessage(messages.customer)} variant="standard" />}
                            />
                        </Grid>
                    }
                    <Grid item xs>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={filters.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitGroupId', obj?.id)}
                            placeholder={messages.unitGroup}
                            compareFn={(o: UnitGroup) => o.id === filters.unitGroupId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<OrderStatus>
                            options={orderStatuses}
                            value={filters.orderStatusIds}
                            onMultipleChange={(obj: OrderStatus[]) => onChange('orderStatusIds', obj.map(o => o.id))}
                            placeholder={messages.status}
                            compareFn={(o: OrderStatus, id: number) => o.id === id}
                            multiple
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<InvoiceType>
                            options={invoiceTypes}
                            value={filters.invoiceTypeId}
                            onChange={(obj: InvoiceType | null) => onChange('invoiceTypeId', obj?.id)}
                            placeholder={messages.invoiceType}
                            compareFn={(o: InvoiceType) => o.id === filters.invoiceTypeId}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <GenericDatePicker
                            name="fromDate"
                            label={messages.validFromDate}
                            value={filters?.fromDate}
                            onChange={onChange}
                            type="start"
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericDatePicker
                            name="toDate"
                            label={messages.validToDate}
                            value={filters?.toDate}
                            onChange={onChange}
                            type="end"
                        />
                    </Grid>
                    {!TableUtils.hideColumn('fromTerminalId', hiddenColumns) &&
                        <Grid item xs>
                            <GenericAutocomplete<Terminal>
                                options={terminals}
                                value={filters.fromTerminalId}
                                onChange={(obj: Terminal | null) => onChange('fromTerminalId', obj?.id)}
                                placeholder={messages.fromTerminal}
                                compareFn={(o: Terminal) => o.id === filters.fromTerminalId}
                            />
                        </Grid>
                    }
                    {!TableUtils.hideColumn('toTerminalId', hiddenColumns) &&
                        <Grid item xs>
                            <GenericAutocomplete<Terminal>
                                options={terminals}
                                value={filters.toTerminalId}
                                onChange={(obj: Terminal | null) => onChange('toTerminalId', obj?.id)}
                                placeholder={messages.toTerminal}
                                compareFn={(o: Terminal) => o.id === filters.toTerminalId}
                            />
                        </Grid>
                    }
                    {!TableUtils.hideColumn('errorTypeId', hiddenColumns) &&
                        <Grid item width={300}>
                            <GenericAutocomplete<ErrorType>
                                options={errorTypes}
                                value={filters.errorTypeId}
                                onChange={(obj: ErrorType | null) => onChange('errorTypeId', obj?.id)}
                                placeholder={messages.errorType}
                                compareFn={(o: ErrorType) => o.id === filters.errorTypeId}
                                label="text"
                            />
                        </Grid>
                    }
                    {!TableUtils.hideColumn('hasErrors', hiddenColumns) &&
                        <Grid item width={300}>
                            <GenericAutocomplete<any>
                                options={showOrders}
                                value={filters.hasErrors}
                                onChange={(obj: any | null) => onChange('hasErrors', obj?.value)}
                                getOptionLabel={(option: any) => option.label?.props?.description}
                                placeholder={messages.errorTypeSelection}
                                compareFn={(o: any) => o?.value === filters.hasErrors}
                            />
                        </Grid>
                    }
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultStateOrders}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}