import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Theme } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useIntl } from 'react-intl';
import TableUtils from '../../../utils/tableUtils';
import DataGridComponent from '../../common/dataGrid';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import NullableFormattedDateWithoutUtc from '../../common/nullableFormattedDateWithoutUtc';
import CustomLink from '../../common/customLink';
import UrlConstants from '../../../constants/UrlConstants';

interface IPrintAgreementVersionPeriodListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    validFromDate: LanguageUtils.createMessage('Valid from date'),
    validToDate: LanguageUtils.createMessage('Valid to date')
};

const PrintAgreementVersionPeriodList = forwardRef((props: IPrintAgreementVersionPeriodListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange, columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'validFromDate',
                headerName: intl.formatMessage(messages.validFromDate),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomLink link={`/#/${UrlConstants.PRINT_AGREEMENT_VERSION_PERIOD_LINES}/${applyRowFn(cellValues).id}`} >
                        <NullableFormattedDateWithoutUtc value={cellValues.row?.validFromDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validFromDate', hiddenColumns)
            },
            {
                field: 'validToDate',
                headerName: intl.formatMessage(messages.validToDate),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink link={`/#/${UrlConstants.PRINT_AGREEMENT_VERSION_PERIOD_LINES}/${applyRowFn(params).id}`} >
                        <NullableFormattedDate value={params.row?.validToDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validToDate', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows} multipleSelection={multipleSelection} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});
export default PrintAgreementVersionPeriodList;