import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import OrderNotYetInvoiced from '../interfaces/output/orderNotYetInvoiced';
import OrdersNotYetInvoicedFilters from '../interfaces/filters/orderNotYetInvoicedFilters';
import moment from 'moment';
import CalculatedPriceListFilters from '../interfaces/filters/calculatedPriceFilters';
import CalculatedPrice from '../interfaces/output/calculatedPrice';
import ProvisionFilters from '../interfaces/filters/kickbackAgreementFilters';
import urlcat from 'urlcat';
import FileWithName from '../interfaces/common/FileWithName';
import { ContentTypeConstants } from '../constants/browserConstants';
import Provision from '../interfaces/output/prevision';
import KickbackAgreementFilters from '../interfaces/filters/kickbackAgreementFilters';
import KickBackPosten from '../interfaces/output/kickBackPosten';

export default class ReportsApi {
    static fetchOrdersNotYetInvoiced = async(paging: Paging, filters?: OrdersNotYetInvoicedFilters): Promise<Pagination<OrderNotYetInvoiced>> => {

        const formattedFilters = {
            ...filters,
            fromDepartureDate: filters?.fromDepartureDate ? moment(filters.fromDepartureDate).toISOString() : undefined,
            toDepartureDate: filters?.toDepartureDate ? moment(filters?.toDepartureDate).toISOString() : undefined
        };

        const url = SortFormatterUtils.addSortingToUrl(paging, formattedFilters, '/reports/notyetinvoiced/aggregated');

        const { data } = await http.get<Pagination<OrderNotYetInvoiced>>(url);

        return data;
    };

    static fetchOrdersNotYetInvoicedFullReport = async(paging: Paging, filters?: OrdersNotYetInvoicedFilters): Promise<Pagination<OrderNotYetInvoiced>> => {

        const formattedFilters = {
            ...filters,
            fromDepartureDate: filters?.fromDepartureDate ? moment(filters.fromDepartureDate).toISOString() : undefined,
            toDepartureDate: filters?.toDepartureDate ? moment(filters?.toDepartureDate).toISOString() : undefined
        };

        const url = SortFormatterUtils.addSortingToUrl(paging, formattedFilters, '/reports/notyetinvoiced/fullreport');

        const { data } = await http.get<Pagination<OrderNotYetInvoiced>>(url);

        return data;
    };

    static fetchCalculatedPrice = async(paging: Paging, filters?: CalculatedPriceListFilters): Promise<Pagination<CalculatedPrice>> => {

        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/reports/calculatedpricelist');

        const { data } = await http.get<Pagination<CalculatedPrice>>(url);

        return data;
    };

    static exportProvisionsCSV = async(searchTerm?: string): Promise<FileWithName> => {

        const url = urlcat('', '/reports/provisions/csv', {
            searchTerm
        });
        const { data, headers } = await http.getFile<Blob>(url, ContentTypeConstants.csv);

        const fileName = headers['content-disposition'].split('filename=')[1].split('.')[0];

        const fileWithName = {
            data: data,
            name: fileName
        } as FileWithName;

        return fileWithName;
    };

    static exportKickbackAgreementPostenCSV = async(filters?: ProvisionFilters): Promise<FileWithName> => {

        const url = urlcat('', '/reports/kickbackAgreementPosten/csv', {
            ...filters
        });
        const { data, headers } = await http.getFile<Blob>(url, ContentTypeConstants.csv);

        const fileName = headers['content-disposition'].split('filename=')[1].split('.')[0];

        const fileWithName = {
            data: data,
            name: fileName
        } as FileWithName;

        return fileWithName;
    };

    static fetchProvisions = async(paging: Paging, searchTerm?: string): Promise<Pagination<Provision>> => {

        const newsort = SortFormatterUtils.sortForServer(paging.sort);
        const newPaging = {
            ...paging,
            sort: ''
        };

        let url = urlcat('', '/reports/provisions', {
            searchTerm,
            ...newPaging
        });

        url = `${url}${newsort}`;
        
        const { data } = await http.get<Pagination<Provision>>(url);

        return data;
    };

    static fetchKickBackPosten = async(paging: Paging, filters?: KickbackAgreementFilters): Promise<Pagination<KickBackPosten>> => {

        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/reports/kickbackAgreementPosten');

        const { data } = await http.get<Pagination<KickBackPosten>>(url);

        return data;
    };
}