import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Theme } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useIntl } from 'react-intl';
import TableUtils from '../../../utils/tableUtils';
import DataGridComponent from '../../common/dataGrid';
import Pagination from '../../../interfaces/common/pagination';
import PrintAgreementVersionPeriodLine from '../../../interfaces/output/printAgreemenVersionPeriodLine';
import TruncatedStringTooltip from '../../common/truncatedStringTooltip';

interface IPrintAgreementVersionPeriodLineListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: Pagination<PrintAgreementVersionPeriodLine>;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    unitType: LanguageUtils.createMessage('Unit type'),
    wightFrom: LanguageUtils.createMessage('Gross weight kg from'),
    wightTo: LanguageUtils.createMessage('Gross weight kg to'),
    departureType: LanguageUtils.createMessage('Departure Type'),
    priorityType: LanguageUtils.createMessage('Priority Type'),
    productPrice: LanguageUtils.createMessage('Product price'),
    grossPrice: LanguageUtils.createMessage('Gross Price'),
    serviceType: LanguageUtils.createMessage('Service type'),
    serviceArenaName: LanguageUtils.createMessage('Service arena name'),
    serviceArenaType: LanguageUtils.createMessage('Service arena Type')

};

const PrintAgreementVersionPeriodLineList = forwardRef((props: IPrintAgreementVersionPeriodLineListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange, columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    const usedLanguage = LanguageUtils.getLanguage();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'fromTerminalId_name',
                headerName: intl.formatMessage(messages.fromTerminal),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(params)?.fromTerminal.name ?? ''} />,
                hide: hiddenColumns && TableUtils.hideColumn('fromTerminal', hiddenColumns)
            },
            {
                field: 'toTerminalId_name',
                headerName: intl.formatMessage(messages.toTerminal),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(params)?.toTerminal.name ?? ''} />,
                hide: hiddenColumns && TableUtils.hideColumn('toTerminal', hiddenColumns)
            },
            {
                field: 'unitTypeName',
                headerName: intl.formatMessage(messages.unitType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.unitType?.name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('unitTypeName', hiddenColumns)
            },
            {
                field: 'grossWeightKgFrom',
                headerName: intl.formatMessage(messages.wightFrom),
                flex: 0.5,
                align: 'right',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.grossWeightKgFrom} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('grossWeightKgFrom', hiddenColumns)
            },
            {
                field: 'grossWeighKgTo',
                headerName: intl.formatMessage(messages.wightTo),
                flex: 0.5,
                align: 'right',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.grossWeighKgTo} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('grossWeighKgTo', hiddenColumns)
            },
            {
                field: 'departureTypeName',
                headerName: intl.formatMessage(messages.departureType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.departureType?.name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('unitTypeName', hiddenColumns)
            },
            {
                field: 'priorityTypeName',
                headerName: intl.formatMessage(messages.priorityType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.priorityType?.name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('unitTypeName', hiddenColumns)
            },
            {
                field: 'calculatedProductPrice',
                headerName: intl.formatMessage(messages.productPrice),
                flex: 0.5,
                align: 'right',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.calculatedProductPrice} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('calculatedProductPrice', hiddenColumns)
            },
            {
                field: 'grossPrice',
                headerName: intl.formatMessage(messages.grossPrice),
                flex: 0.5,
                align: 'right',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.grossPrice} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('grossPrice', hiddenColumns)
            },
            {
                field: 'serviceType',
                headerName: intl.formatMessage(messages.serviceType),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.serviceType?.name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('serviceType', hiddenColumns)
            },
            {
                field: 'serviceArena',
                headerName: intl.formatMessage(messages.serviceArenaName),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={usedLanguage === 'nb-NO' ?
                        applyRowFn(params)?.serviceArena?.name : applyRowFn(params)?.serviceArena?.nameNo} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('serviceArena', hiddenColumns)
            },
            {
                field: 'serviceArenaType',
                headerName: intl.formatMessage(messages.serviceArenaType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: false,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={usedLanguage === 'nb-NO' ?
                        applyRowFn(params)?.serviceArena?.serviceArenaType.nameNo : applyRowFn(params)?.serviceArena?.serviceArenaType.name} />;
                },
                hide: true
            }

        ];
    }

    return (
        <DataGridComponent rows={rows} multipleSelection={multipleSelection} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});
export default PrintAgreementVersionPeriodLineList;