import { createTheme } from '@mui/material/styles';
const primary = '#FEB400';
const secondary = '#363636';
const blue = '#416b80';
const white = '#ffffff';
const black = '#000000';
const lightGray = '#F0F0ED';
const error = '#cc0033';
const success = '#339966';
const customer = '#66CC99';
const agreement = '#66CCCC';
const priceList = '#996699';
const product = '#CC6699';
const factorSet = '#3399FF';
const onHold = '#fcc200';
const deleted = '#d73b3e';
const inProgress = '#a8e4a0';
const expired = '#cc8586';
const complete = '#32a83e';
const invoiced = '#bbe3ce';
const gtsOrder = '#ff9966';
const order = '#006a4e';
const orderUnit = '#bcd4e6';
const service = '#9e2a3d';
const dashboard = '#948aab';
const specialPrice = '#bba0fa';
const invoice = '#7fb0a4';
const invoiceLines = '#064a08';
const simulation = '#82f586';
const discount = '#a11a98';
const surchargeRate = '#948aab';
const user = '#bbbb33';
const terminal = '#a33a55';
const productArena = '#a44a66';
const responsibilityCenter = '#ff8990';
const printAgreementVersionPeriodLines = '#379b85';

export default createTheme({
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    padding: '0!important'
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    maxWidth: '2000px!important'
                }
            }
        }
    },
    palette: {
        primary: {
            main: primary,
            light: customer
        },
        secondary: { main: secondary },
        info: { main: blue },
        common: {
            white: white,
            black: black
        },
        lightGray: { main: lightGray },
        error: { main: error },
        success: { main: success },
        customer: { main: customer },
        agreement: { main: agreement },
        priceList: { main: priceList },
        product: { main: product },
        factorSet: { main: factorSet },
        gtsOrder: { main: gtsOrder },
        order: { main: order },
        orderUnit: { main: orderUnit },
        onHold: { main: onHold },
        deleted: { main: deleted },
        inProgress: { main: inProgress },
        expired: { main: expired },
        invoiced: { main: invoiced },
        service: { main: service },
        complete: { main: complete },
        dashboard: { main: dashboard },
        specialPrice: { main: specialPrice },
        invoice: { main: invoice },
        invoiceLines: { main: invoiceLines },
        simulation: { main: simulation },
        discount: { main: discount },
        surchargeRate: { main: surchargeRate },
        user: { main: user },
        terminal: { main: terminal },
        productArena: { main: productArena },
        responsibilityCenter: { main: responsibilityCenter },
        printAgreementVersionPeriodLines: { main: printAgreementVersionPeriodLines }
    },
    typography: {
        h1: { color: secondary },
        h2: { color: secondary },
        h3: { color: secondary },
        h4: { color: secondary },
        h5: { color: secondary },
        h6: { color: secondary },
        subtitle1: { color: secondary },
        subtitle2: { color: blue }
    }
});

declare module '@mui/material/styles' {
    interface Palette {
        lightGray: Palette['primary'];
        customer: Palette['primary'];
        agreement: Palette['primary'];
        product: Palette['primary'];
        priceList: Palette['primary'];
        factorSet: Palette['primary'];
        gtsOrder: Palette['primary'];
        orderUnit: Palette['primary'];
        order: Palette['primary'];
        onHold: Palette['primary'];
        deleted: Palette['primary'];
        inProgress: Palette['primary'];
        expired: Palette['primary'];
        invoiced: Palette['primary'];
        service: Palette['primary'];
        complete: Palette['primary'];
        dashboard: Palette['primary'];
        specialPrice: Palette['primary'];
        invoice: Palette['primary'];
        invoiceLines: Palette['primary'];
        simulation: Palette['primary'];
        discount: Palette['primary'];
        surchargeRate: Palette['primary'];
        user: Palette['primary'];
        terminal: Palette['primary'];
        productArena: Palette['primary'];
        responsibilityCenter: Palette['primary'];
        printAgreementVersionPeriodLines: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        lightGray?: PaletteOptions['primary'];
        customer?: PaletteOptions['primary'];
        agreement?: PaletteOptions['primary'];
        product?: PaletteOptions['primary'];
        priceList?: PaletteOptions['primary'];
        factorSet?: PaletteOptions['primary'];
        gtsOrder?: PaletteOptions['primary'];
        orderUnit?: PaletteOptions['primary'];
        order?: PaletteOptions['primary'];
        onHold?: PaletteOptions['primary'];
        deleted?: PaletteOptions['primary'];
        inProgress?: PaletteOptions['primary'];
        expired?: PaletteOptions['primary'];
        invoiced?: PaletteOptions['primary'];
        service?: PaletteOptions['primary'];
        complete?: PaletteOptions['primary'];
        dashboard?: PaletteOptions['primary'];
        specialPrice?: PaletteOptions['primary'];
        invoice?: PaletteOptions['primary'];
        simulation?: PaletteOptions['primary'];
        invoiceLines?: PaletteOptions['primary'];
        discount?: PaletteOptions['primary'];
        surchargeRate?: PaletteOptions['primary'];
        user: PaletteOptions['primary'];
        terminal: PaletteOptions['primary'];
        productArena: PaletteOptions['primary'];
        responsibilityCenter: PaletteOptions['primary']
        printAgreementVersionPeriodLines: PaletteOptions['primary']
    }
}
