import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import PrintAgreementVersionPeriodLineFilters from '../../../interfaces/filters/printAgreementVersionPeriodLineFilters';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import { fetchTerminals, getTerminals } from '../../../reducers/terminalReducer';
import { fetchOrderStatuses } from '../../../reducers/orderStatusReducer';
import { FormattedMessage } from 'react-intl';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import Terminal from '../../../interfaces/output/terminal';
import { fetchServiceArenaTypes, getServiceArenaTypes } from '../../../reducers/serviceArenaTypeReducer';
import { fetchUnitTypes, getUnitTypes } from '../../../reducers/unitTypeReducer';
import UnitType from '../../../interfaces/output/unitType';
import ServiceArenaType from '../../../interfaces/output/serviceArenaType';
import { fetchServiceTypes, getServiceTypes } from '../../../reducers/serviceTypeReducer';
import ServiceType from '../../../interfaces/output/serviceType';
import { fetchServicesArena, getServicesArena } from '../../../reducers/serviceArenaReducer';
import ServiceArenaFilters from '../../../interfaces/filters/serviceArenaFilters';
import ServiceArena from '../../../interfaces/output/serviceArena';

interface IFilterProps {
    filters: PrintAgreementVersionPeriodLineFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
}

const messages = {
    unitType: LanguageUtils.createMessage('Unit type'),
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    serviceArenaType: LanguageUtils.createMessage('Service arena type'),
    serviceType: LanguageUtils.createMessage('Service type'),
    serviceArena: LanguageUtils.createMessage('Service arena name'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')

};

export default function PrintAgreementVersionPeriodLineFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState } = props;
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const terminals = useSelector(getTerminals).content;
    const unitTypes = useSelector(getUnitTypes).content;
    const serviceArenaTypes = useSelector(getServiceArenaTypes).content;
    const serviceTypes = useSelector(getServiceTypes).content;
    const servicesArena = useSelector(getServicesArena).content;

    useEffect(() => {
        dispatch(fetchUnitTypes({ paging }));
        dispatch(fetchOrderStatuses({ paging }));
        dispatch(fetchTerminals({ paging }));
        dispatch(fetchServicesArena({
            paging,
            filters: {} as ServiceArenaFilters
        }));
        dispatch(fetchServiceArenaTypes({ paging }));
        dispatch(fetchServiceTypes({ paging }));
    }, [dispatch]);

    const setDefaultStateOrders = () => {
        setDefaultState();
    };

    const usedLanguage = LanguageUtils.getLanguage();

    return (
        <Grid container pr={2} pl={2} direction="column">
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.terminalFromIds}
                            onMultipleChange={(obj: Terminal[]) => onChange('terminalFromIds', obj.map(o => o.id))}
                            placeholder={messages.fromTerminal}
                            compareFn={(o: Terminal, id: number) => o.id === id}
                            getOptionLabel={(option: Terminal) => option?.name || ''}
                            multiple
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.terminalToIds}
                            onMultipleChange={(obj: Terminal[]) => onChange('terminalToIds', obj.map(o => o.id))}
                            placeholder={messages.toTerminal}
                            compareFn={(o: Terminal, id: number) => o.id === id}
                            getOptionLabel={(option: Terminal) => option?.name || ''}
                            multiple
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<UnitType>
                            options={unitTypes}
                            value={filters.unitTypeIds}
                            onMultipleChange={(obj: UnitType[]) => onChange('unitTypeIds', obj.map(o => o.id))}
                            placeholder={messages.unitType}
                            compareFn={(o: UnitType, id: number) => o.id === id}
                            getOptionLabel={(option: UnitType) => option?.name || ''}
                            multiple
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <GenericAutocomplete<ServiceType>
                            options={serviceTypes}
                            value={filters.serviceTypeIds}
                            onMultipleChange={(obj: ServiceType[]) => onChange('serviceTypeIds', obj.map(o => o.id))}
                            placeholder={messages.serviceType}
                            compareFn={(o: ServiceType, id: number) => o.id === id}
                            getOptionLabel={(option: ServiceType) => option?.name || ''}
                            multiple
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<ServiceArena>
                            options={servicesArena}
                            value={filters.serviceArenaIds}
                            onMultipleChange={(obj: ServiceArena[]) => onChange('serviceArenaIds', obj.map(o => o.id))}
                            placeholder={messages.serviceArena}
                            compareFn={(o: ServiceArena, id: number) => o.id === id}
                            getOptionLabel={(option: ServiceArena) => usedLanguage === 'nb-NO' ? option.nameNo : option.name || ''}
                            multiple
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<ServiceArenaType>
                            options={serviceArenaTypes}
                            value={filters.serviceArenaTypeIds}
                            onMultipleChange={(obj: ServiceArenaType[]) => onChange('serviceArenaTypeIds', obj.map(o => o.id))}
                            placeholder={messages.serviceArenaType}
                            compareFn={(o: ServiceArenaType, id: number) => o.id === id}
                            getOptionLabel={(option: ServiceArenaType) => usedLanguage === 'nb-NO' ? option.nameNo : option.name || ''}
                            multiple
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultStateOrders}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}