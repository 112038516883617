import React from 'react';
import { Theme } from '@mui/material';
import UrlConstants from '../../../constants/UrlConstants';
import Pagination from '../../../interfaces/common/pagination';
import DataGridComponent from '../../common/dataGrid';
import PriceList from '../../../interfaces/output/priceList';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import TableUtils from '../../../utils/tableUtils';
import { useIntl } from 'react-intl';
import DisplayStatus from '../../common/displayStatus';
import PricelistVersionProduct from '../../../interfaces/output/pricelistVersionProduct';
import LanguageUtils from '../../../utils/LanguageUtils';
import PriceListVersion from '../../../interfaces/output/priceListVersions';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import CustomLink from '../../common/customLink';
import CustomStringLink from '../../common/customStringLink';
import Status from '../../../interfaces/output/status';
import { StatusCodes } from '../../../constants/statusConstants';

interface IPricelistListProps {
    classes?: any;
    theme?: Theme;
    pricelists?: Pagination<PriceList>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    defaultSelectionModel?: Array<string | number | undefined>;
    hiddenColumns?: Array<string>;
    applyRowFn?: any;
    onSortModelChange?: any;
    prefix?: string;
    secondPrefix?:string;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    versionNumber: LanguageUtils.createMessage('Version number'),
    validFromDate: LanguageUtils.createMessage('Valid from'),
    validToDate: LanguageUtils.createMessage('Valid to'),
    factorSetWeight: LanguageUtils.createMessage('Factor set weight'),
    factorSetDeparture: LanguageUtils.createMessage('Factor set departure'),
    factorSetPriority: LanguageUtils.createMessage('Factor set priority'),
    factorSetCancellation: LanguageUtils.createMessage('Factor set cancellation'),
    factorSetHours: LanguageUtils.createMessage('Factor set hours'),
    name: LanguageUtils.createMessage('Price list name'),
    status: LanguageUtils.createMessage('Status'),
    basePrice: LanguageUtils.createMessage('Base price'),
    onHold: LanguageUtils.createMessage('On hold'),
    factorSetDangerousGoods: LanguageUtils.createMessage('Factor set dangerous goods'),
    factorSetCalendars: LanguageUtils.createMessage('Factor set calendar')
};

const PricelistVersionList = ((props: IPricelistListProps): JSX.Element => {
    const { pricelists, componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, onSortModelChange, prefix,secondPrefix,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => (cellValues as any).row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const generateColumns = (): GridColDef[] => {
        const intl = useIntl();

        return [
            {
                field: secondPrefix ? `${secondPrefix}name` : 'name',
                headerName: intl.formatMessage(messages.name),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams<PriceListVersion>) => {
                    return <CustomStringLink link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}
                        value={params.row.pricelist && params.row.pricelist.name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('name', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}versionNumber` : 'versionNumber',
                headerName: intl.formatMessage(messages.versionNumber),
                flex: 0.5,
                sortable: true,
                valueGetter: (params: GridValueGetterParams<PriceListVersion>) => applyRowFn(params)?.versionNumber ?? '-',
                renderCell: (params: GridRenderCellParams<PriceListVersion>) => {
                    return <CustomStringLink link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`} value={applyRowFn(params)?.versionNumber ?? '-'} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('versionNumber', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}validFromDate` : 'validFromDate',
                headerName: intl.formatMessage(messages.validFromDate),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}>
                        <NullableFormattedDate value={applyRowFn(params)?.validFromDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validFromDate', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}validToDate` : 'validToDate',
                headerName: intl.formatMessage(messages.validToDate),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}>
                        <NullableFormattedDate value={applyRowFn(params)?.validToDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validToDate', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}factorSetWeight_name` : 'factorSetWeight',
                headerName: intl.formatMessage(messages.factorSetWeight),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}
                    value={applyRowFn(params)?.factorSetWeight?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('factorSetWeight', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}factorSetDeparture_name` : 'factorSetDeparture',
                headerName: intl.formatMessage(messages.factorSetDeparture),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}
                    value={applyRowFn(params)?.factorSetDeparture?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('factorSetDeparture', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}factorSetPriority_name` : 'factorSetPriority',
                headerName: intl.formatMessage(messages.factorSetPriority),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}
                    value={applyRowFn(params)?.factorSetPriority?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('factorSetPriority', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}factorSetCancellation_name` : 'factorSetCancellation',
                headerName: intl.formatMessage(messages.factorSetCancellation),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}
                    value={applyRowFn(params)?.factorSetCancellation?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('factorSetCancellation', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}factorSetHours_name` : 'factorSetHours',
                headerName: intl.formatMessage(messages.factorSetHours),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}
                    value={applyRowFn(params)?.factorSetHours?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('factorSetHours', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}factorSetCalendar_name` : 'factorSetCalendar',
                headerName: intl.formatMessage(messages.factorSetCalendars),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}
                    value={applyRowFn(params)?.factorSetCalendar?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('factorSetCalendar', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}factorSetDangerousGoods_name` : 'factorSetDangerousGoods',
                headerName: intl.formatMessage(messages.factorSetDangerousGoods),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}
                    value={applyRowFn(params)?.factorSetDangerousGoods?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('factorSetDangerousGoods', hiddenColumns)
            },
            {
                field: 'status',
                headerName: intl.formatMessage(messages.status),
                flex: 1,
                align: 'center',
                headerAlign: 'left',
                sortable: false,
                renderCell: (params: GridRenderCellParams) => <CustomLink link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}>
                    <DisplayStatus status={applyRowFn(params)?.status} inheritProps />
                </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('status', hiddenColumns)
            },
            // {
            //     field: 'basePrice',
            //     headerName: intl.formatMessage(messages.basePrice),
            //     flex: 1,
            //     align: 'left',
            //     headerAlign: 'left',
            //     sortable: false,
            //     renderCell: (params: GridRenderCellParams<PricelistVersionProduct>) => <CustomStringLink
            //         link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}
            //         value={params.row.basePrice ?? '-'} />,
            //     hide: hiddenColumns && TableUtils.hideColumn('basePrice', hiddenColumns)
            // },
            {
                field: 'onHold',
                headerName: intl.formatMessage(messages.onHold),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams<PricelistVersionProduct>) => <CustomLink
                    link={`/#/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.pricelistId}/${applyRowFn(params)?.id}`}>
                    <DisplayStatus
                        status={{
                            code: params?.row?.onHold ?
                                StatusCodes.True : StatusCodes.False
                        } as Status} inheritProps />
                </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('onHold', hiddenColumns)
            }
        ];
    };

    return (
        <DataGridComponent rows={rows || pricelists} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} onSortModelChange={onSortModelChange} columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default PricelistVersionList;