import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import TableUtils from '../../../utils/tableUtils';
import UrlConstants from '../../../constants/UrlConstants';
import CustomStringLink from '../../common/customStringLink';
import HelptextConstantsEnum from '../../../constants/helptextConstantsEnum';
import DataGridComponent from '../../common/dataGrid';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import CustomLink from '../../common/customLink';

interface OrdersNotYetInvoicedListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
    disableLinks?: boolean;
}

const messages = {
    customerName: LanguageUtils.createMessage('Customer Name'),
    departureYear: LanguageUtils.createMessage('Departure Year'),
    departureMonth: LanguageUtils.createMessage('Departure Month'),
    ousStatusName: LanguageUtils.createMessage('Ous Status'),
    currencyCode: LanguageUtils.createMessage('Currency'),
    amount: LanguageUtils.createMessage('Amount'),
    revenue: LanguageUtils.createMessage('Revenue'),
    nextInvoiceDate: LanguageUtils.createMessage('Next Invoice Date'),
    serviceGroupName: LanguageUtils.createMessage('Service group'),
    totalRevenue: LanguageUtils.createMessage('Total revenue')
};

const OrdersNotYetInvoicedList = forwardRef((props: OrdersNotYetInvoicedListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.REPORT_ORDERS_NOT_INVOICES);
        }
    }, []);

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'customerNoName',
                headerName: intl.formatMessage(messages.customerName),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (param: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(param)?.customerNoName}
                        link={`/#/${UrlConstants.ORDER_NOT_YET_INVOICED_FULL_REPORT}/${applyRowFn(param)?.customerId}/${applyRowFn(param)?.departureMonth}/${applyRowFn(param)?.departureYear}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('customerNoName', hiddenColumns)
            },
            {
                field: 'departureYear',
                headerName: `${intl.formatMessage(messages.departureYear)}`,
                flex: 0.7,
                align: 'right',
                sortable: true,
                renderCell: (param: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(param)?.departureYear}
                        link={`/#/${UrlConstants.ORDER_NOT_YET_INVOICED_FULL_REPORT}/${applyRowFn(param)?.customerId}/${applyRowFn(param)?.departureMonth}/${applyRowFn(param)?.departureYear}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('code', hiddenColumns)
            },
            {
                field: 'departureMonth',
                headerName: intl.formatMessage(messages.departureMonth),
                flex: 0.5,
                align: 'right',
                headerAlign: 'left',
                sortable: true,
                renderCell: (param: any) => {
                    return <CustomStringLink value={applyRowFn(param)?.departureMonth}
                        link={`/#/${UrlConstants.ORDER_NOT_YET_INVOICED_FULL_REPORT}/${applyRowFn(param)?.customerId}/${applyRowFn(param)?.departureMonth}/${applyRowFn(param)?.departureYear}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('fromDate', hiddenColumns)
            },
            {
                field: 'ousStatusName',
                headerName: intl.formatMessage(messages.ousStatusName),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (param: any) => {
                    return <CustomStringLink value={applyRowFn(param)?.ousStatusName}
                        link={`/#/${UrlConstants.ORDER_NOT_YET_INVOICED_FULL_REPORT}/${applyRowFn(param)?.customerId}/${applyRowFn(param)?.departureMonth}/${applyRowFn(param)?.departureYear}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('ousStatusName', hiddenColumns)
            },
            {
                field: 'serviceGroupNameNo',
                headerName: intl.formatMessage(messages.serviceGroupName),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (param: any) => {
                    return <CustomStringLink value={applyRowFn(param)?.serviceGroupNameNo}
                        link={`/#/${UrlConstants.ORDER_NOT_YET_INVOICED_FULL_REPORT}/${applyRowFn(param)?.customerId}/${applyRowFn(param)?.departureMonth}/${applyRowFn(param)?.departureYear}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('serviceGroupNameNo', hiddenColumns)
            },
            {
                field: 'currencyCode',
                headerName: intl.formatMessage(messages.currencyCode),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (param: any) => {
                    return <CustomStringLink value={applyRowFn(param)?.currencyCode}
                        link={`/#/${UrlConstants.ORDER_NOT_YET_INVOICED_FULL_REPORT}/${applyRowFn(param)?.customerId}/${applyRowFn(param)?.departureMonth}/${applyRowFn(param)?.departureYear}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('currencyCode', hiddenColumns)
            },
            {
                field: 'amount',
                headerName: intl.formatMessage(messages.amount),
                flex: 0.3,
                align: 'right',
                headerAlign: 'left',
                sortable: true,
                renderCell: (param: any) => {
                    return <CustomStringLink value={applyRowFn(param)?.amount}
                        link={`/#/${UrlConstants.ORDER_NOT_YET_INVOICED_FULL_REPORT}/${applyRowFn(param)?.customerId}/${applyRowFn(param)?.departureMonth}/${applyRowFn(param)?.departureYear}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('amount', hiddenColumns)
            },
            {
                field: 'revenue',
                headerName: intl.formatMessage(messages.revenue),
                flex: 0.5,
                align: 'right',
                headerAlign: 'left',
                sortable: true,
                renderCell: (param: any) => {
                    return <CustomStringLink value={applyRowFn(param)?.revenue}
                        link={`/#/${UrlConstants.ORDER_NOT_YET_INVOICED_FULL_REPORT}/${applyRowFn(param)?.customerId}/${applyRowFn(param)?.departureMonth}/${applyRowFn(param)?.departureYear}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('revenue', hiddenColumns)
            },
            {
                field: 'totalRevenue',
                headerName: intl.formatMessage(messages.totalRevenue),
                flex: 0.5,
                align: 'right',
                headerAlign: 'left',
                sortable: true,
                renderCell: (param: any) => {
                    return <CustomStringLink value={applyRowFn(param)?.totalRevenue}
                        link={`/#/${UrlConstants.ORDER_NOT_YET_INVOICED_FULL_REPORT}/${applyRowFn(param)?.customerId}/${applyRowFn(param)?.departureMonth}/${applyRowFn(param)?.departureYear}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('totalRevenue', hiddenColumns)
            },
            {
                field: 'nextInvoiceDate',
                headerName: intl.formatMessage(messages.nextInvoiceDate),
                flex: 0.8,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (param: any) => {
                    return (
                        <CustomLink
                            link={`/#/${UrlConstants.ORDER_NOT_YET_INVOICED_FULL_REPORT}/${applyRowFn(param)?.customerId}/${applyRowFn(param)?.departureMonth}/${applyRowFn(param)?.departureYear}`}>
                            <NullableFormattedDate value={applyRowFn(param)?.nextInvoiceDate} />
                        </CustomLink>);
                },
                hide: hiddenColumns && TableUtils.hideColumn('nextInvoiceDate', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.customerNoName + row?.amount + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default OrdersNotYetInvoicedList;
