import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import OrderNotYetInvoiced from '../interfaces/output/orderNotYetInvoiced';
import OrdersNotYetInvoicedFilters from '../interfaces/filters/orderNotYetInvoicedFilters';
import CalculatedPrice from '../interfaces/output/calculatedPrice';
import CalculatedPriceListFilters from '../interfaces/filters/calculatedPriceFilters';
import ProvisionFilters from '../interfaces/filters/kickbackAgreementFilters';
import Provision from '../interfaces/output/prevision';
import KickbackAgreementFilters from '../interfaces/filters/kickbackAgreementFilters';
import KickBackPosten from '../interfaces/output/kickBackPosten';

export type ReportsState = {
    isLoadingOrdersNotYetInvoiced: boolean;
    ordersNotYetInvoiced: Pagination<OrderNotYetInvoiced>;
    error: HttpErrorResponse | undefined;
    isLoadingCalculatedPrice: boolean;
    calculatedPrice: Pagination<CalculatedPrice>;
    orderNotYetInvoicedFullReport: Pagination<OrderNotYetInvoiced>;
    isLoadingOrderNotYetInvoicedFullReport: boolean;
    isExportKickbackAgreementPostenCSV: boolean;
    isExportProvisionsCSV: boolean;
    isLoadingProvisions: boolean;
    provisions: Pagination<Provision>;
    isLoadingKickBackPosten: boolean;
    kickBackPostens: Pagination<KickBackPosten>;
};

const initialState: ReportsState = {
    ordersNotYetInvoiced: { content: [] as Array<OrderNotYetInvoiced> } as Pagination<OrderNotYetInvoiced>,
    isLoadingOrdersNotYetInvoiced: false,
    error: undefined,
    isLoadingCalculatedPrice: false,
    calculatedPrice: { content: [] as Array<CalculatedPrice> } as Pagination<CalculatedPrice>,
    orderNotYetInvoicedFullReport: { content: [] as Array<OrderNotYetInvoiced> } as Pagination<OrderNotYetInvoiced>,
    isLoadingOrderNotYetInvoicedFullReport: false,
    isExportKickbackAgreementPostenCSV: false,
    isExportProvisionsCSV: false,
    isLoadingProvisions: false,
    provisions: { content: [] as Array<Provision> } as Pagination<Provision>,
    kickBackPostens: { content: [] as Array<KickBackPosten> } as Pagination<KickBackPosten>,
    isLoadingKickBackPosten: false
};

export const reportsSlice = createSlice({
    name: 'reports',

    initialState,
    reducers: {
        fetchOrdersNotYetInvoiced: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: OrdersNotYetInvoicedFilters }>) => {
            state.isLoadingOrdersNotYetInvoiced = true;
        },
        fetchOrdersNotYetInvoicedSuccess: (state: ReportsState, action: PayloadAction<Pagination<OrderNotYetInvoiced>>) => {
            state.isLoadingOrdersNotYetInvoiced = false;
            state.ordersNotYetInvoiced = action.payload;
        },
        fetchOrdersNotYetInvoicedError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOrdersNotYetInvoiced = false;
            state.error = action.payload;
        },
        resetOrdersNotYetInvoiced: (state: ReportsState) => {
            state.ordersNotYetInvoiced = initialState.ordersNotYetInvoiced;
        },
        fetchOrdersNotYetInvoicedFullReport: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: OrdersNotYetInvoicedFilters }>) => {
            state.isLoadingOrderNotYetInvoicedFullReport = true;
        },
        fetchOrdersNotYetInvoicedFullReportSuccess: (state: ReportsState, action: PayloadAction<Pagination<OrderNotYetInvoiced>>) => {
            state.isLoadingOrderNotYetInvoicedFullReport = false;
            state.orderNotYetInvoicedFullReport = action.payload;
        },
        fetchOrdersNotYetInvoicedFullReportError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOrderNotYetInvoicedFullReport = false;
            state.error = action.payload;
        },
        resetOrdersNotYetInvoicedFullReport: (state: ReportsState) => {
            state.orderNotYetInvoicedFullReport = initialState.ordersNotYetInvoiced;
        },
        fetchCalculatedPrice: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: CalculatedPriceListFilters }>) => {
            state.isLoadingCalculatedPrice = true;
        },
        fetchCalculatedPriceSuccess: (state: ReportsState, action: PayloadAction<Pagination<CalculatedPrice>>) => {
            state.isLoadingCalculatedPrice = false;
            state.calculatedPrice = action.payload;
        },
        fetchCalculatedPriceError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCalculatedPrice = false;
            state.error = action.payload;
        },
        resetCalculatedPrice: (state: ReportsState) => {
            state.calculatedPrice = initialState.calculatedPrice;
        },
        exportProvisionsCSV: (state: ReportsState, _action: PayloadAction<{ searchTerm?: string }>) => {
            state.isExportProvisionsCSV = true;
        },
        exportProvisionsCSVSuccess: (state: ReportsState) => {
            state.isExportProvisionsCSV = false;
        },
        exportProvisionsCSVError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExportProvisionsCSV = false;
            state.error = action.payload;
        },
        exportKickbackAgreementPostenCSV: (state: ReportsState, _action: PayloadAction<{ filters?: ProvisionFilters }>) => {
            state.isExportKickbackAgreementPostenCSV = true;
        },
        exportKickbackAgreementPostenCSVSuccess: (state: ReportsState) => {
            state.isExportKickbackAgreementPostenCSV = false;
        },
        exportKickbackAgreementPostenCSVError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExportKickbackAgreementPostenCSV = false;
            state.error = action.payload;
        },
        fetchProvisions: (state: ReportsState, _action: PayloadAction<{ paging: Paging, searchTerm?: string }>) => {
            state.isLoadingProvisions = true;
        },
        fetchProvisionsSuccess: (state: ReportsState, action: PayloadAction<Pagination<Provision>>) => {
            state.isLoadingProvisions = false;
            state.provisions = action.payload;
        },
        fetchProvisionsError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingProvisions = false;
            state.error = action.payload;
        },
        resetProvisions: (state: ReportsState) => {
            state.provisions = initialState.provisions;
        },
        fetchKickBackPosten: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: KickbackAgreementFilters }>) => {
            state.isLoadingKickBackPosten = true;
        },
        fetchKickBackPostenSuccess: (state: ReportsState, action: PayloadAction<Pagination<KickBackPosten>>) => {
            state.isLoadingKickBackPosten = false;
            state.kickBackPostens = action.payload;
        },
        fetchKickBackPostenError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingKickBackPosten = false;
            state.error = action.payload;
        },
        resetKickBackPosten: (state: ReportsState) => {
            state.kickBackPostens = initialState.kickBackPostens;
        }
    }
});

export const { fetchOrdersNotYetInvoiced, fetchOrdersNotYetInvoicedError, fetchOrdersNotYetInvoicedSuccess, resetOrdersNotYetInvoiced,
    fetchCalculatedPrice, fetchCalculatedPriceError, fetchCalculatedPriceSuccess, resetCalculatedPrice,
    fetchOrdersNotYetInvoicedFullReport, fetchOrdersNotYetInvoicedFullReportError, fetchOrdersNotYetInvoicedFullReportSuccess, resetOrdersNotYetInvoicedFullReport,
    exportKickbackAgreementPostenCSV, exportKickbackAgreementPostenCSVError, exportKickbackAgreementPostenCSVSuccess, exportProvisionsCSV, exportProvisionsCSVError,
    exportProvisionsCSVSuccess, fetchProvisions, fetchProvisionsSuccess, fetchProvisionsError, fetchKickBackPosten,
    fetchKickBackPostenError, fetchKickBackPostenSuccess, resetKickBackPosten, resetProvisions }
    = reportsSlice.actions;

export const isLoadingOrdersNotYetInvoiced = (state: RootState): boolean => state.reportsReducer.isLoadingOrdersNotYetInvoiced;
export const getOrdersNotYetInvoiced = (state: RootState): Pagination<OrderNotYetInvoiced> => state.reportsReducer.ordersNotYetInvoiced;
export const isLoadingCalculatedPrice = (state: RootState): boolean => state.reportsReducer.isLoadingCalculatedPrice;
export const getCalculatedPrice = (state: RootState): Pagination<CalculatedPrice> => state.reportsReducer.calculatedPrice;
export const isLoadingOrdersNotYetInvoicedFullReport = (state: RootState): boolean => state.reportsReducer.isLoadingOrderNotYetInvoicedFullReport;
export const getOrdersNotYetInvoicedFullReport = (state: RootState): Pagination<OrderNotYetInvoiced> => state.reportsReducer.orderNotYetInvoicedFullReport;
export const isExportProvisionsCSV = (state: RootState): boolean => state.reportsReducer.isExportProvisionsCSV;
export const isExportKickbackAgreementPostenCSV = (state: RootState): boolean => state.reportsReducer.isExportKickbackAgreementPostenCSV;
export const isLoadingProvisions = (state: RootState): boolean => state.reportsReducer.isLoadingProvisions;
export const getProvisions = (state: RootState): Pagination<Provision> => state.reportsReducer.provisions;
export const isLoadingKickBackPosten = (state: RootState): boolean => state.reportsReducer.isLoadingKickBackPosten;
export const getKickBackPosten = (state: RootState): Pagination<KickBackPosten> => state.reportsReducer.kickBackPostens;
export default reportsSlice.reducer;