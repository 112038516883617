import { Theme, Grid, Container, Button, Paper } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import IMenuItem from '../../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../../common/widgets/mainLayout';
import LanguageUtils from '../../../utils/LanguageUtils';
import { exportProvisionsCSV, fetchProvisions, getProvisions, isLoadingProvisions, resetProvisions } from '../../../reducers/reportsReducer';
import QuickSearchToolbar from '../../common/quickSearchToolbar';
import { FormattedMessage } from 'react-intl';
import UrlConstants from '../../../constants/UrlConstants';
import Pagination from '../../../interfaces/common/pagination';
import Provision from '../../../interfaces/output/prevision';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import Paging from '../../../interfaces/common/paging';
import PageUtils from '../../../utils/pageUtils';
import ProvisionList from './previsionList';

interface IProvisionCSVProps {
    classes: any;
    exportProvisionsCSV: any;
    isLoading: boolean;
    onChange: () => void;
    fetch: any;
    provisions: Pagination<Provision>;
    fetchProvisions: any;
    resetProvisions: () => void;
}

interface IProvisionCSVState {
    searchTerm: string;
    menuItem: IMenuItem;
    paging: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    prevision: LanguageUtils.createMessage('Provisions'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters'),
    download: LanguageUtils.createMessage('Download csv')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    exportProvisionsCSV: (searchTerm: string) => dispatch(exportProvisionsCSV({
        searchTerm
    })),
    fetchProvisions: (paging: Paging, searchTerm: string) => dispatch(fetchProvisions({
        paging,
        searchTerm
    })),
    resetProvisions: () => dispatch(resetProvisions())
});

const mapStoreToProps = (store: RootState) => {
    return {
        isLoading: isLoadingProvisions(store),
        provisions: getProvisions(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class ProvisionCSV extends Component<IProvisionCSVProps, IProvisionCSVState> {
    provisionsListRef: any;
    constructor(props: IProvisionCSVProps) {
        super(props);
        this.provisionsListRef = React.createRef();

        this.state = {
            searchTerm: '',
            paging: PageUtils.getDefaultPaging([{
                field: 'accountName',
                sort: 'asc'
            } as GridSortItem]),
            menuItem: {
                id: 1,
                name: messages.prevision,
                icon: <FontAwesomeIcon icon="table" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: 'accountName',
                sort: 'asc' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchProvisions } = this.props;
        const { paging, searchTerm } = this.state;

        fetchProvisions(paging, searchTerm);
    }

    componentWillUnmount() {
        const { resetProvisions } = this.props;
        resetProvisions();
    }

    exportCSV = () => {
        const { exportProvisionsCSV } = this.props;
        const { searchTerm } = this.state;

        exportProvisionsCSV(searchTerm);
    }

    applyFilters = () => {
        const { fetchProvisions } = this.props;
        const { paging, searchTerm } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchProvisions(newPagination, searchTerm);

        if(this.provisionsListRef !== null && this.provisionsListRef !== undefined &&
            this.provisionsListRef.current !== null && this.provisionsListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.provisionsListRef.current.resetDataGridPage();
        }
    }

    onChange = (value: any) => {
        const { paging } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            searchTerm: value,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchProvisions } = this.props;
        const { paging } = this.state;

        const newSortModel = [{
            field: 'accountName',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            searchTerm: ''
        });

        fetchProvisions(newPagination);

        if(this.provisionsListRef !== null && this.provisionsListRef !== undefined &&
            this.provisionsListRef.current !== null && this.provisionsListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.provisionsListRef.current.resetDataGridPage();
        }
    }

    onChangeQuickSearch = (value: any) => {
        const { paging } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            searchTerm: value,
            paging: newPagination
        });
    };

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchProvisions } = this.props;
        const { sortModel, searchTerm, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchProvisions(newPaging, searchTerm);

        }
    }

    render() {
        const { classes, isLoading, provisions, fetchProvisions } = this.props;
        const { searchTerm, menuItem, paging, columnVisibilityModel } = this.state;

        return (
            <MainLayout
                menuItem={menuItem}
                routes={
                    [{
                        name: 'go to Reports',
                        url: `${UrlConstants.REPORTS}`
                    }]}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <QuickSearchToolbar
                                    searchTerm={searchTerm}
                                    onSearch={this.onChangeQuickSearch}
                                    actions={[{
                                        text: messages.download,
                                        icon: 'download',
                                        onClick: this.exportCSV
                                    }]}
                                />
                                <Grid item>
                                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                                        <Grid item>
                                            <Button onClick={this.setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={this.applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <ProvisionList rows={provisions} isLoading={isLoading}
                                    ref={this.provisionsListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchProvisions(newPage, searchTerm);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(ProvisionCSV));