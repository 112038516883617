import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import PrintAgreementVersion from '../interfaces/output/printAgreemenVersion';
import PrintAgreementVersionPeriod from '../interfaces/output/printAgreemenVersionPeriod';
import urlcat from 'urlcat';
import { ContentTypeConstants } from '../constants/browserConstants';
import PrintAgreementVersionPeriodFilters from '../interfaces/filters/printAgreementVersionPeriodFilters';
import PrintAgreementVersionCalculatedPrice from '../interfaces/output/printAgreemenVersionCalculatedPrice';
import PrintAgreementVersionPeriodLineFilters from '../interfaces/filters/printAgreementVersionPeriodLineFilters';
import PrintAgreementVersionPeriodLine from '../interfaces/output/printAgreemenVersionPeriodLine';
import FileWithName from '../interfaces/common/FileWithName';

export default class PrintAgreementVersionApi {
    static fetchPrintAgreementVersion = async(agreementVersionId: number): Promise<PrintAgreementVersion> => {
        //agreementVersionId = 384;
        const { data } = await http.get<PrintAgreementVersion>(`/printagreementversion/${agreementVersionId}`);

        return data;

    }

    static fetchPrintAgreementVersionPeriod = async(paging: Paging, filters?: PrintAgreementVersionPeriodFilters): Promise<Pagination<PrintAgreementVersionPeriod>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/printagreementversionperiod');

        const { data } = await http.get<Pagination<PrintAgreementVersionPeriod>>(url);

        return data;

    }

    static fetchPrintAgreementVersionPeriodById = async(printAgreementVersionPeriodId: number): Promise<PrintAgreementVersionPeriod> => {
        const { data } = await http.get<PrintAgreementVersionPeriod>(`/printagreementversionperiod/${printAgreementVersionPeriodId}`);

        return data;

    }

    static fetchPrintAgreementVersionPeriodLines = async(paging: Paging, filters?: PrintAgreementVersionPeriodLineFilters): Promise<Pagination<PrintAgreementVersionPeriodLine>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/printagreementversionperiodline');

        const { data } = await http.get<Pagination<PrintAgreementVersionPeriodLine>>(url);

        return data;

    }

    static exportPrintAgreementVersionPeriodLines = async(filters?: PrintAgreementVersionPeriodLineFilters): Promise<FileWithName> => {

        const url = urlcat('', '/agreement/print/pdf', {
            ...filters
        });

        const { data, headers } = await http.getFile<File>(url, ContentTypeConstants.pdf);
        const fileName = headers['content-disposition'].split('filename=')[1].split('.')[0];

        const fileWithName = {
            data: data,
            name: fileName
        } as FileWithName;

        return fileWithName;
    };

    static exportPrintAgreementVersionPeriodLinesCSV = async(filters?: PrintAgreementVersionPeriodLineFilters): Promise<FileWithName> => {

        const url = urlcat('', '/agreement/print/csv', {
            ...filters
        });
        const { data, headers } = await http.getFile<Blob>(url, ContentTypeConstants.csv);

        const fileName = headers['content-disposition'].split('filename=')[1].split('.')[0];

        const fileWithName = {
            data: data,
            name: fileName
        } as FileWithName;

        return fileWithName;
    };

    static runProcedurePrintAgreementVersion = async(id: number): Promise<PrintAgreementVersion> => {
        const newParam = {
            agreementVersionId: id
        };

        const url = urlcat('', '/printagreementversion/calculateprice', {
            ...newParam
        });
        const { data } = await http.put<PrintAgreementVersionCalculatedPrice>(url);

        return data;

    }
}