const UrlConstants = {
    AGREEMENTS: 'agreements',
    AGREEMENT_VERSION_SPECIAL_PRICE: 'agreementversionspecialprice',
    AGREEMENT_VERSION_TRANSIT_DISCOUNT: 'agreementversiontransitdiscount',
    AGREEMENT_VERSION_PRICE_LIST: 'agreementversionpricelists',
    CORPORATES: 'corporate',
    CUSTOMERS: 'customers',
    CUSTOMER_AGREMEENTS: 'customeragreements',
    DASHBOARD: '/',
    FACTOR_SETS: 'factorsets',
    FACTOR_SET_WEIGHT_LINES: 'factorsetweightlines',
    FACTOR_SET_PRIORITY_LINES: 'factorsetprioritylines',
    FACTOR_SET_DEPARTURE_LINES: 'factorsetdeparturelines',
    FACTOR_SET_HOURS_LINES: 'factorsethourslines',
    FACTOR_SET_CANCELLATION_LINES: 'factorsetcancellationlines',
    FACTOR_SET_CANCELLATION: 'cancellation',
    FACTOR_SET_PRIORITY: 'priority',
    FACTOR_SET_WEIGHT: 'weight',
    FACTOR_SET_HOURS: 'hours',
    FACTOR_SET_DEPARTURE: 'departure',
    FACTOR_SET_DANGEROUS_GOODS: 'dangerousgoods',
    FACTOR_SET_DANGEROUS_GOODS_LINES: 'dangerousgoodslines',
    FACTOR_SET_CALENDAR: 'calendar',
    FACTOR_SET_CALENDAR_LINES: 'calendarlines',
    INVOICES: 'invoices',
    INVOICE_LINES: 'invoicelines',
    ORDERS: 'orders',
    ORDER_UNITS: 'orderunits',
    ORDER_UNIT_SERVICES: 'orderunitservices',
    ORDER_UNIT_SERVICE_PRODUCTS: 'orderunitserviceproducts',
    ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES: 'orderunitserviceproductsurcharges',
    PRICE_LISTS: 'pricelists',
    PRICE_LIST_VERSION_PRODUCTS: 'pricelistversionproducts',
    PRODUCTS: 'products',
    SERVICES: 'services',
    SIMULATIONS: 'simulations',
    PROPERTIES: 'properties',
    VERSIONS: 'agreementversion',
    VERSION_STATUSES: '/agreementversionstatus',
    GTS_ORDERS: 'staginggtsorders',
    GTS_ORDER_UNITS: 'staginggtsorderunits',
    GTS_UNIT_SERVICES: 'staginggtsorderunitservicelines',
    UNIT_DEPOT: 'staginggtsorderunitdepotlines',
    UNIT_DANGEROUS_GOODS: 'staginggtsorderunitdangerousgoodslines',
    ERROR: 'error',
    SURCHARGE_RATES: 'surchargeRates',
    USERS: 'users',
    SERVICE_PRODUCTS: 'serviceproducts',
    TERMINALS: 'terminals',
    PRODUCT_ARENAS: 'productarenas',
    REPORTS_ORDERS_NOT_INVOICED: 'reports/ordersnotinvoiced',
    RESPONSIBILITIES_CENTER: 'responsibilitiescenter',
    REPORTS_CALCULATED_PRICE: 'calculatedPrice',
    PRINT_AGREEMENT_VERSION_PERIOD_LINES: 'printAgreementVersionPeriodLines',
    ORDER_NOT_YET_INVOICED_FULL_REPORT: 'reports/orderNotYetInvoicedFullReport',
    ORDER_NOT_YET_INVOICED: 'reports/orderNotYetInvoiced',
    REPORTS: 'reports',
    PROVISIONS: 'provisions',
    KICKBACK_AGREEMENT_POSTEN: 'kickbackAgreementPosten'
};

export default UrlConstants;
