import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import {
    exportPrintAgreementVersionPeriodLinesCSVError, exportPrintAgreementVersionPeriodLinesCSVSuccess,
    exportPrintAgreementVersionPeriodLinesError, exportPrintAgreementVersionPeriodLinesSuccess, fetchPrintAgreementVersionError,
    fetchPrintAgreementVersionPeriodByIdError,
    fetchPrintAgreementVersionPeriodByIdSuccess,
    fetchPrintAgreementVersionPeriodError, fetchPrintAgreementVersionPeriodLinesError, fetchPrintAgreementVersionPeriodLinesSuccess,
    fetchPrintAgreementVersionPeriodSuccess, fetchPrintAgreementVersionSuccess, runProcedurePrintAgreementVersionError,
    runProcedurePrintAgreementVersionSuccess
} from '../reducers/printAgreementVersionReducer';
import PrintAgreementVersion from '../interfaces/output/printAgreemenVersion';
import FileSaver from 'file-saver';
import PrintAgreementVersionPeriodFilters from '../interfaces/filters/printAgreementVersionPeriodFilters';
import PrintAgreementVersionPeriod from '../interfaces/output/printAgreemenVersionPeriod';
import PrintAgreementVersionApi from '../api/printAgreementVersionApi';
import PrintAgreementVersionPeriodLineFilters from '../interfaces/filters/printAgreementVersionPeriodLineFilters';
import PrintAgreementVersionPeriodLine from '../interfaces/output/printAgreemenVersionPeriodLine';
import FileWithName from '../interfaces/common/FileWithName';

function* doFetchPrintAgreementVersion(action: PayloadAction<number>) {
    const agreementVersionId = action.payload;

    try {
        const response: PrintAgreementVersion = yield call(PrintAgreementVersionApi.fetchPrintAgreementVersion, agreementVersionId);
        yield put(fetchPrintAgreementVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPrintAgreementVersionError(error as HttpErrorResponse));
    }
}

function* doFetchPrintAgreementVersionPeriodById(action: PayloadAction<number>) {
    const printAgreementVersionPeriodId = action.payload;

    try {
        const response: PrintAgreementVersionPeriod = yield call(PrintAgreementVersionApi.fetchPrintAgreementVersionPeriodById, printAgreementVersionPeriodId);
        yield put(fetchPrintAgreementVersionPeriodByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPrintAgreementVersionPeriodByIdError(error as HttpErrorResponse));
    }
}

function* doFetchPrintAgreementVersionPeriod(action: PayloadAction<{ paging: Paging, filters?: PrintAgreementVersionPeriodFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<PrintAgreementVersionPeriod> = yield call(PrintAgreementVersionApi.fetchPrintAgreementVersionPeriod, paging, filters);
        yield put(fetchPrintAgreementVersionPeriodSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPrintAgreementVersionPeriodError(error as HttpErrorResponse));
    }
}

function* doFetchPrintAgreementVersionPeriodLines(action: PayloadAction<{ paging: Paging, filters?: PrintAgreementVersionPeriodLineFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<PrintAgreementVersionPeriodLine> = yield call(PrintAgreementVersionApi.fetchPrintAgreementVersionPeriodLines, paging, filters);
        yield put(fetchPrintAgreementVersionPeriodLinesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPrintAgreementVersionPeriodLinesError(error as HttpErrorResponse));
    }
}

function* doExportPrintAgreementVersioPeriodLines(action: PayloadAction<{ filters?: PrintAgreementVersionPeriodLineFilters }>) {
    const { filters } = action.payload;

    try {
        const { data, name }: FileWithName = yield call(PrintAgreementVersionApi.exportPrintAgreementVersionPeriodLines, filters);
        FileSaver.saveAs(data, name);
        yield put(exportPrintAgreementVersionPeriodLinesSuccess());
    }

    catch (error: unknown) {
        yield put(exportPrintAgreementVersionPeriodLinesError(error as HttpErrorResponse));
    }
}

function* doExportPrintAgreementVersioPeriodLinesCSV(action: PayloadAction<{ filters?: PrintAgreementVersionPeriodLineFilters }>) {
    const { filters } = action.payload;

    try {
        const { data, name }: FileWithName = yield call(PrintAgreementVersionApi.exportPrintAgreementVersionPeriodLinesCSV, filters);
        FileSaver.saveAs(data, name);
        yield put(exportPrintAgreementVersionPeriodLinesCSVSuccess());
    }

    catch (error: unknown) {
        yield put(exportPrintAgreementVersionPeriodLinesCSVError(error as HttpErrorResponse));
    }
}

function* doRunProcedurePrintAgreementVersion(action: PayloadAction<number>) {
    const id = action.payload;

    try {
        const response: PrintAgreementVersion = yield call(PrintAgreementVersionApi.runProcedurePrintAgreementVersion, id);
        yield put(runProcedurePrintAgreementVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(runProcedurePrintAgreementVersionError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('printAgreementVersion/fetchPrintAgreementVersion', doFetchPrintAgreementVersion),
    takeLatest('printAgreementVersion/fetchPrintAgreementVersionPeriod', doFetchPrintAgreementVersionPeriod),
    takeLatest('printAgreementVersion/exportPrintAgreementVersionPeriodLines', doExportPrintAgreementVersioPeriodLines),
    takeLatest('printAgreementVersion/exportPrintAgreementVersionPeriodLinesCSV', doExportPrintAgreementVersioPeriodLinesCSV),
    takeLatest('printAgreementVersion/runProcedurePrintAgreementVersion', doRunProcedurePrintAgreementVersion),
    takeLatest('printAgreementVersion/fetchPrintAgreementVersionPeriodLines', doFetchPrintAgreementVersionPeriodLines),
    takeLatest('printAgreementVersion/fetchPrintAgreementVersionPeriodById', doFetchPrintAgreementVersionPeriodById)
];

