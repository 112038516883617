import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Pagination from '../../../interfaces/common/pagination';
import PrintAgreementVersionPeriodLine from '../../../interfaces/output/printAgreemenVersionPeriodLine';
import PrintAgreementVersionPeriodLineFilters from '../../../interfaces/filters/printAgreementVersionPeriodLineFilters';
import Paging from '../../../interfaces/common/paging';
import LanguageUtils from '../../../utils/LanguageUtils';
import IMenuItem from '../../../interfaces/common/menuItem';
import { RootState } from '../../../setup';
import {
    fetchPrintAgreementVersionPeriodById,
    fetchPrintAgreementVersionPeriodLines, getPrintAgreementVersionPeriodById, getPrintAgreementVersionPeriodLines,
    isLoadingPrintAgreementVersionPeriodById,
    isLoadingPrintAgreementVersionPeriodLines, resetPrintAgreementVersionPeriodById, resetPrintAgreementVersionPeriodLines
} from '../../../reducers/printAgreementVersionReducer';
import PageUtils from '../../../utils/pageUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../../common/widgets/mainLayout';
import PrintAgreementVersionPeriodLineFilterComponent from './printAgreementVersionPeriodLineFilters';
import UrlConstants from '../../../constants/UrlConstants';
import PrintAgreementVersionPeriodLineList from './printAgreementVersionPeriodLineList';
import { List as ImmutableList } from 'immutable';
import { ObjectType } from '../../../constants/constants';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import PrintAgreementVersionPeriod from '../../../interfaces/output/printAgreemenVersionPeriod';
import ExportPrintAgreementVersionPeriod from './printAgreementVersionPeriodLineExport';
import NullableFormattedDateWithoutUtc from '../../common/nullableFormattedDateWithoutUtc';

interface IPrintAgreementVersionPeriodLinesProps {
    printAgreementVersionPeriodLines: Pagination<PrintAgreementVersionPeriodLine>;
    classes: any;
    fetchOrders: any;
    isLoading: boolean;
    clearSearch: () => void;
    onChange: () => void;
    resetPrintAgreementVersionPeriodLines: () => void;
    fetchPrintAgreementVersionPeriodLines: any;
    match: any;
    printAgreementVersionPeriodById: PrintAgreementVersionPeriod;
    fetchPrintAgreementVersionPeriodById: any;
    resetPrintAgreementVersionPeriodById: () => void;
}

interface IPrintAgreementVersionPeriodLinesState {
    searchTerm: string;
    filters: PrintAgreementVersionPeriodLineFilters;
    paging: Paging;
    menuItems: ImmutableList<IMenuItem>;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
    selectedRow?: number;
    openPrintAgreementModal: boolean;
}

const messages = {
    printAgreementVersionPeriodLines: LanguageUtils.createMessage('Print Agreement version period lines'),
    add: LanguageUtils.createMessage('Add'),
    export: LanguageUtils.createMessage('Export')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchPrintAgreementVersionPeriodLines: (paging: Paging, filters: PrintAgreementVersionPeriodLineFilters) => dispatch(fetchPrintAgreementVersionPeriodLines({
        paging,
        filters
    })),
    fetchPrintAgreementVersionPeriodById: (printAgreementVersionPeriodId: number) => dispatch(fetchPrintAgreementVersionPeriodById(printAgreementVersionPeriodId)),
    resetPrintAgreementVersionPeriodLines: () => dispatch(resetPrintAgreementVersionPeriodLines()),
    resetPrintAgreementVersionPeriodById: () => dispatch(resetPrintAgreementVersionPeriodById())
});

const mapStoreToProps = (store: RootState) => {
    return {
        printAgreementVersionPeriodLines: getPrintAgreementVersionPeriodLines(store),
        printAgreementVersionPeriodById: getPrintAgreementVersionPeriodById(store),
        isLoading: isLoadingPrintAgreementVersionPeriodLines(store) || isLoadingPrintAgreementVersionPeriodById(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class PrintAgreementVersionPeriodDetails extends Component<IPrintAgreementVersionPeriodLinesProps, IPrintAgreementVersionPeriodLinesState> {
    printAgreementVersionPeriodLineListRef: any;

    constructor(props: IPrintAgreementVersionPeriodLinesProps) {
        super(props);
        this.printAgreementVersionPeriodLineListRef = React.createRef();

        this.state = {
            searchTerm: '',
            filters: {
            } as PrintAgreementVersionPeriodLineFilters,
            paging: PageUtils.getDefaultPaging(),
            menuItems: ImmutableList([{
                id: 1,
                name: messages.printAgreementVersionPeriodLines,
                icon: <FontAwesomeIcon icon="print" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.PRINT_AGREEMENT_VERSION_PERIOD_LINES}/${props.match.params.printAgreementVersionPeriodId}`,
                isSelected: true
            }]),
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {
                serviceType: false,
                serviceArenaType: false,
                serviceArena: false
            },
            selectedRow: undefined,
            openPrintAgreementModal: false
        };
    }

    componentDidMount() {
        const { printAgreementVersionPeriodId } = this.props.match.params;
        const { fetchPrintAgreementVersionPeriodLines, fetchPrintAgreementVersionPeriodById } = this.props;
        const { paging, filters } = this.state;
        const newFilter = {
            ...filters,
            printAgreementVersionPeriodId: printAgreementVersionPeriodId
        };
        fetchPrintAgreementVersionPeriodLines(paging, newFilter);
        fetchPrintAgreementVersionPeriodById(printAgreementVersionPeriodId);
    }

    componentWillUnmount() {
        const { resetPrintAgreementVersionPeriodLines, resetPrintAgreementVersionPeriodById } = this.props;
        resetPrintAgreementVersionPeriodLines();
        resetPrintAgreementVersionPeriodById();
    }

    applyFilters = () => {
        const { printAgreementVersionPeriodId } = this.props.match.params;
        const { fetchPrintAgreementVersionPeriodLines } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        const newFilter = {
            ...filters,
            printAgreementVersionPeriodId: printAgreementVersionPeriodId
        };
        this.setState({
            paging: newPagination,
            filters: newFilter
        });

        fetchPrintAgreementVersionPeriodLines(newPagination, newFilter);

        if(this.printAgreementVersionPeriodLineListRef !== null && this.printAgreementVersionPeriodLineListRef !== undefined &&
            this.printAgreementVersionPeriodLineListRef.current !== null && this.printAgreementVersionPeriodLineListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.printAgreementVersionPeriodLineListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as PrintAgreementVersionPeriodLineFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { printAgreementVersionPeriodId } = this.props.match.params;
        const { fetchPrintAgreementVersionPeriodLines } = this.props;
        const { paging } = this.state;
        // const now = new Date();
        const newFilters = {
            printAgreementVersionPeriodId: printAgreementVersionPeriodId
        } as PrintAgreementVersionPeriodLineFilters;

        const newSortModel = [{
            field: '',
            sort: '' as GridSortDirection
        } as GridSortItem];

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        fetchPrintAgreementVersionPeriodLines(newPagination, newFilters);

        if(this.printAgreementVersionPeriodLineListRef !== null && this.printAgreementVersionPeriodLineListRef !== undefined &&
            this.printAgreementVersionPeriodLineListRef.current !== null && this.printAgreementVersionPeriodLineListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.printAgreementVersionPeriodLineListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchPrintAgreementVersionPeriodLines } = this.props;
        const { sortModel, filters, paging } = this.state;
        const { printAgreementVersionPeriodId } = this.props.match.params;
        const newFilters = {
            ...filters,
            printAgreementVersionPeriodId: printAgreementVersionPeriodId
        };

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging,
                filters: newFilters
            });

            fetchPrintAgreementVersionPeriodLines(newPaging, newFilters);
        }
    }
    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        //const { agreementVersionSpecialPriceId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        text: messages.export,
                        icon: 'download',
                        onClick: () => this.setState({ openPrintAgreementModal: true })
                    }
                ];
            }

            default: {
                return [];
            }
        }

    }

    _setPrintAgreementOpenModal = () => {
        const { openPrintAgreementModal } = this.state;
        this.setState({ openPrintAgreementModal: !openPrintAgreementModal });
    }

    render() {
        const { classes, printAgreementVersionPeriodLines, isLoading, fetchPrintAgreementVersionPeriodLines, printAgreementVersionPeriodById } = this.props;
        const { filters, menuItems, paging, columnVisibilityModel, openPrintAgreementModal } = this.state;
        const { printAgreementVersionPeriodId } = this.props.match.params;
        const agreementId = printAgreementVersionPeriodById?.printAgreementVersion?.agreementVersion?.agreementId ?? '';
        const agreementVersionId = printAgreementVersionPeriodById?.printAgreementVersion?.agreementVersion?.id ?? '';

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoading}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.PrintAgreementVersionPeriodLines}
                menuItem={{
                    id: 1,
                    subname: messages.printAgreementVersionPeriodLines,
                    icon: <FontAwesomeIcon icon="print" size="1x" />
                } as IMenuItem}
                additionalElement={
                    <strong>
                        <span className={classes.values}>
                            <NullableFormattedDateWithoutUtc value={printAgreementVersionPeriodById?.validFromDate} /> - <NullableFormattedDate value={printAgreementVersionPeriodById?.validToDate} />
                        </span>
                    </strong>
                }
                routes={
                    [{
                        name: `go to agreement with version ${agreementVersionId}`,
                        url: `${UrlConstants.AGREEMENTS}/${agreementId}/${agreementVersionId}`
                    }]
                }>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <PrintAgreementVersionPeriodLineFilterComponent applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                {/* </QuickSearchToolbar> */}
                                <PrintAgreementVersionPeriodLineList
                                    rows={printAgreementVersionPeriodLines} isLoading={isLoading}
                                    ref={this.printAgreementVersionPeriodLineListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        this.setState({ paging: newPage });
                                        fetchPrintAgreementVersionPeriodLines(newPage,
                                            {
                                                ...filters,
                                                printAgreementVersionPeriodId: printAgreementVersionPeriodId
                                            } as PrintAgreementVersionPeriodLineFilters);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                    <ExportPrintAgreementVersionPeriod setOpenModal={this._setPrintAgreementOpenModal} open={openPrintAgreementModal} printAgreementVersionPeriodId={printAgreementVersionPeriodId}
                        printAgreementVersionPeriodLineFilters={filters} />
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(PrintAgreementVersionPeriodDetails));