import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton, Menu, Button, Theme, Typography, Grid, MenuItem, AppBar, Toolbar, Box } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import FontAwesomeIcon from '../../utils/FontAwesomeIcons';
import ContextIndicator from './widgets/contextIndicator';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Logo from '../../assets/logo_nav.png';
import LogoTest from '../../assets/logo_test_nav.png';
import LogoStage from '../../assets/logo_stage_nav.png';
import Puzzle from '../../assets/puzzle.png';
import PuzzleTest from '../../assets/puzzle_test.png';
import UrlConstants from '../../constants/UrlConstants';
import { getUserDetails, userLogout } from '../../reducers/userReducer';
import { useDispatch, useSelector } from 'react-redux';
import { hasRoles } from '../../utils/roleUtils';
import RoleConstants from '../../constants/roleConstants';

const messages = {
    account: LanguageUtils.createMessage('Account'),
    invoices: LanguageUtils.createMessage('Invoices'),
    capri: LanguageUtils.createMessage('Capri'),
    signOut: LanguageUtils.createMessage('Sign out'),
    dashboard: LanguageUtils.createMessage('Dashboard'),
    customers: LanguageUtils.createMessage('Customers'),
    agreements: LanguageUtils.createMessage('Agreements'),
    products: LanguageUtils.createMessage('Products'),
    orders: LanguageUtils.createMessage('Orders'),
    services: LanguageUtils.createMessage('Services'),
    priority: LanguageUtils.createMessage('Priority'),
    weight: LanguageUtils.createMessage('Weight'),
    departure: LanguageUtils.createMessage('Departure'),
    hours: LanguageUtils.createMessage('Hours'),
    cancellation: LanguageUtils.createMessage('Cancellation'),
    factorSets: LanguageUtils.createMessage('Factor sets'),
    gtsOrders: LanguageUtils.createMessage('Gts orders'),
    priceLists: LanguageUtils.createMessage('Price lists'),
    simulations: LanguageUtils.createMessage('Simulations'),
    dangerousGoods: LanguageUtils.createMessage('Dangerous goods'),
    calendar: LanguageUtils.createMessage('Calendar'),
    surchargeRate: LanguageUtils.createMessage('Surcharge Rates'),
    users: LanguageUtils.createMessage('Users'),
    terminals: LanguageUtils.createMessage('Terminals'),
    productArenas: LanguageUtils.createMessage('Product arenas'),
    admin: LanguageUtils.createMessage('Admin'),
    reports: LanguageUtils.createMessage('Reports'),
    responsabilitiesCenter: LanguageUtils.createMessage('Responsibilities center')
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: `${theme.zIndex.drawer + 1} !important` as any,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            backgroundColor: process.env.REACT_APP_ENV !== 'production' ? '#b2ff59!important' : '#ffff'
        },
        menuButtons: {
            '&.active': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                '&:hover': {
                    backgroundColor: theme.palette.primary.main
                }
            }
        },
        popUpButtons: {
            flexDirection: 'column'
        },
        account: {
            color: theme.palette.common.white
        },
        logo: {
            maxWidth: 226,
            float: 'left'
        },
        accountIcon: {
            backgroundColor: `${theme.palette.info.main}!important`,
            borderRadius: '0px !important',
            padding: '15px!important',
            marginRight: `${theme.spacing(0.5)}!important`,
            height: '64px'
        },
        puzzle: {
            position: 'absolute',
            height: 64,
            width: 84,
            right: 0,
            backgroundSize: 'cover',
            backgroundImage: `url(${process.env.REACT_APP_ENV !== 'production' ? PuzzleTest : Puzzle})`
        },
        typography: {
            fontSize: '8.5px!important',
            textTransform: 'uppercase',
            color: 'black'
        },
        typographyItem: {
            // backgroundColor: 'white',
            // marginRight: '-6px!important',
            // marginLeft: '-6px!important',
            // paddingRight: theme.spacing(1),
            // paddingLeft: theme.spacing(1),
            // width: '122%'
        },
        marginRight: {
            marginRight: theme.spacing(1)
        },
        toolbarItems: {
            display: 'flex',
            height: '100%'
        },
        items: {
            //height: '10vh',
            position: 'relative',
            '&.a': {
                position: 'absolute'
            }
        },
        itemsGrid: {
            height: '90%',
            maxHeight: '50%'
        }
    })
);

export default function Navbar(): JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(getUserDetails);
    const _handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const _handleClose = () => {
        setAnchorEl(null);
    };
    const noAccess = hasRoles([RoleConstants.Roles.ROLE_FINANCIAL, RoleConstants.Roles.ROLE_PRODUCT, RoleConstants.Roles.ROLE_ADMIN], user.roles);
    const isAdmin = hasRoles([RoleConstants.Roles.ROLE_ADMIN], user.roles);
    const isFinancial = hasRoles([RoleConstants.Roles.ROLE_FINANCIAL], user.roles);

    return (
        <AppBar color="inherit" position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbarItems}>
                <Box className={classes.items} sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'flex'
                    }
                }}>
                    <a href="/#/">
                        <img src={(process.env.REACT_APP_ENV === 'stage') ? LogoStage : (process.env.REACT_APP_ENV !== 'production') ? LogoTest : Logo} alt="logo" className={classes.logo} />
                    </a>
                </Box>

                <Box className={classes.items} sx={{
                    display: 'flex',
                    flexGrow: 1
                }}>
                    <ContextIndicator />
                </Box>
                <Grid className={classes.itemsGrid} container direction="row" spacing={0.5} mr={5} width="auto">
                    <Grid item>
                        <Button color="info" className={classes.menuButtons} component={NavLink} exact
                            to="/" title={formatMessage(messages.dashboard)}
                        >
                            <Grid container direction="column" alignItems="center">
                                <Grid item>
                                    <FontAwesomeIcon icon="comments" size="2x" title="Dashboard" transform="shrink-2" />
                                </Grid>
                                <Grid item className={classes.typographyItem}>
                                    <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.dashboard} /></Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="info" className={classes.menuButtons} component={NavLink} exact
                            to="/customers" title={formatMessage(messages.customers)}
                        >
                            <Grid container direction="column" alignItems="center">
                                <Grid item>
                                    <FontAwesomeIcon icon="user-friends" size="2x" title="Customers" transform="shrink-2" />
                                </Grid>
                                <Grid item className={classes.typographyItem}>
                                    <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.customers} /></Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="info" className={classes.menuButtons} component={NavLink} exact
                            to="/orders" title={formatMessage(messages.orders)}
                        >
                            <Grid container direction="column" alignItems="center">
                                <Grid item>
                                    <FontAwesomeIcon icon="file-contract" size="2x" transform="shrink-2" />
                                </Grid>
                                <Grid item className={classes.typographyItem}>
                                    <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.orders} /></Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    {(isFinancial || isAdmin) &&
                        <Grid item>
                            <Button color="info" className={classes.menuButtons} component={NavLink} exact
                                to="/staginggtsorders" title={formatMessage(messages.gtsOrders)}
                            >
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <FontAwesomeIcon icon="receipt" size="2x" transform="shrink-2" />
                                    </Grid>
                                    <Grid item className={classes.typographyItem}>
                                        <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.gtsOrders} /></Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    }
                    <Grid item>
                        <Button color="info" className={classes.menuButtons} component={NavLink} exact
                            to="/agreements" title={formatMessage(messages.agreements)}
                        ><Grid container direction="column" alignItems="center">
                                <Grid item>
                                    <FontAwesomeIcon icon="handshake" size="2x" transform="shrink-2" />
                                </Grid>
                                <Grid item className={classes.typographyItem}>
                                    <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.agreements} /></Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="info" className={classes.menuButtons} component={NavLink} exact
                            to="/pricelists" title={formatMessage(messages.priceLists)}
                        >
                            <Grid container direction="column" alignItems="center">
                                <Grid item>
                                    <FontAwesomeIcon icon="tags" size="2x" transform="shrink-2" />
                                </Grid>
                                <Grid item className={classes.typographyItem}>
                                    <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.priceLists} /></Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    {noAccess &&
                        <Grid item>
                            <Button color="info" className={classes.menuButtons} component={NavLink} exact
                                to="/services" title={formatMessage(messages.services)}
                            >
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <FontAwesomeIcon icon="concierge-bell" size="2x" transform="shrink-2" />
                                    </Grid>
                                    <Grid item className={classes.typographyItem}>
                                        <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.services} /></Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    }
                    {noAccess &&
                        <Grid item>
                            <Button color="info" className={classes.menuButtons} component={NavLink} exact
                                to="/products" title={formatMessage(messages.products)}
                            >
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <FontAwesomeIcon icon="cubes" size="2x" transform="shrink-2" />
                                    </Grid>
                                    <Grid item className={classes.typographyItem}>
                                        <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.products} /></Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    }
                    <Grid item>
                        <Button color="info" className={classes.menuButtons} component={NavLink} exact
                            to={`/${UrlConstants.INVOICES}`} title={formatMessage(messages.invoices)}
                        >
                            <Grid container direction="column" alignItems="center">
                                <Grid item>
                                    <FontAwesomeIcon icon="file-invoice" size="2x" transform="shrink-2" />
                                </Grid>
                                <Grid item className={classes.typographyItem}>
                                    <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.invoices} /></Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    {isAdmin &&
                        <Grid item>
                            <Button color="info" className={classes.menuButtons} component={NavLink} exact
                                to="/surchargeRates" title={formatMessage(messages.surchargeRate)}
                            >
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <FontAwesomeIcon icon="file-invoice-dollar" size="2x" transform="shrink-2" />
                                    </Grid>
                                    <Grid item className={classes.typographyItem}>
                                        <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.surchargeRate} /></Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    }
                    {/* {isAdmin &&
                    <Grid item>
                        <Button color="info" className={classes.menuButtons} component={NavLink} exact
                            to="/users" title={formatMessage(messages.users)}
                        >
                            <Grid container direction="column" alignItems="center">
                                <Grid item>
                                    <FontAwesomeIcon icon="user-friends" size="2x" title="Users" transform="shrink-2" />
                                </Grid>
                                <Grid item className={classes.typographyItem}>
                                    <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.users} /></Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    } */}
                    {/* {isAdmin &&
                    <Grid item>
                        <Button color="info" className={classes.menuButtons} component={NavLink} exact
                            to="/terminals" title={formatMessage(messages.terminals)}
                        >
                            <Grid container direction="column" alignItems="center">
                                <Grid item>
                                    <FontAwesomeIcon icon="train" size="2x" title="Terminals" transform="shrink-2" />
                                </Grid>
                                <Grid item className={classes.typographyItem}>
                                    <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.terminals} /></Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    } */}
                    {/* {isAdmin &&
                    <Grid item>
                        <Button color="info" className={classes.menuButtons} component={NavLink} exact
                            to="/productArenas" title={formatMessage(messages.productArenas)}
                        >
                            <Grid container direction="column" alignItems="center">
                                <Grid item>
                                    <FontAwesomeIcon icon="clock" size="2x" title="Product Arenas" transform="shrink-2" />
                                </Grid>
                                <Grid item className={classes.typographyItem}>
                                    <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.productArenas} /></Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    } */}
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                            <Grid item>
                                <PopupState variant="popover" popupId="demo-popup-menu">
                                    {(popupState: any) => (
                                        <React.Fragment>
                                            <Button color="info" className={classes.menuButtons} {...bindTrigger(popupState)}>
                                                <FontAwesomeIcon icon="user-friends" size="2x" title="Admin" transform="shrink-2" />
                                            </Button>
                                            <Menu {...bindMenu(popupState)}>
                                                <Grid container>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/users" title={formatMessage(messages.users)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="user-friends" size="2x" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.users} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/terminals" title={formatMessage(messages.terminals)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="train" size="2x" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.terminals} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/productArenas" onClick={popupState.close} title={formatMessage(messages.productArenas)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="warehouse" size="2x" title="Product Arenas" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.productArenas} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/responsibilitiesCenter" onClick={popupState.close} title={formatMessage(messages.responsabilitiesCenter)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="flag" size="2x" title="Responsibilities Center" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.responsabilitiesCenter} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Menu>
                                        </React.Fragment>
                                    )}
                                </PopupState>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.admin} /></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                            <Grid item>
                                <PopupState variant="popover" popupId="demo-popup-menu">
                                    {(popupState: any) => (
                                        <React.Fragment>
                                            <Button color="info" className={classes.menuButtons} {...bindTrigger(popupState)}>
                                                <FontAwesomeIcon icon="chart-bar" size="2x" title="Simulations and Reports" transform="shrink-2" />
                                            </Button>
                                            <Menu {...bindMenu(popupState)}>
                                                <Grid container>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/simulations" title={formatMessage(messages.simulations)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="chart-bar" size="2x" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.simulations} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/reports" title={formatMessage(messages.reports)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="chart-line" size="2x" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.reports} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                    {/* <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/productArenas" onClick={popupState.close} title={formatMessage(messages.productArenas)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="warehouse" size="2x" title="Product Arenas" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.productArenas} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid> */}
                                                </Grid>
                                            </Menu>
                                        </React.Fragment>
                                    )}
                                </PopupState>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.simulations} /></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item>
                        <Button color="info" className={classes.menuButtons} component={NavLink} exact
                            to="/simulations" title={formatMessage(messages.simulations)}
                        >
                            <Grid container direction="column" alignItems="center">
                                <Grid item>
                                    <FontAwesomeIcon icon="chart-bar" size="2x" transform="shrink-2" />
                                </Grid>
                                <Grid item className={classes.typographyItem}>
                                    <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.simulations} /></Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid> */}
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                            <Grid item>
                                <PopupState variant="popover" popupId="demo-popup-menu">
                                    {(popupState: any) => (
                                        <React.Fragment>
                                            <Button color="info" className={classes.menuButtons} {...bindTrigger(popupState)}>
                                                <FontAwesomeIcon icon="sliders-h" size="2x" title="Factor sets" transform="shrink-2" />
                                            </Button>
                                            <Menu {...bindMenu(popupState)}>
                                                <Grid container>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/factorsets/weight" onClick={popupState.close} title={formatMessage(messages.weight)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="weight-hanging" size="2x" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.weight} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/factorsets/priority" title={formatMessage(messages.priority)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="flag" size="2x" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.priority} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/factorsets/departure" title={formatMessage(messages.departure)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="calendar-check" size="2x" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.departure} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/factorsets/hours" title={formatMessage(messages.hours)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="clock" size="2x" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.hours} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/factorsets/cancellation" title={formatMessage(messages.cancellation)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="window-close" size="2x" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.cancellation} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/factorsets/dangerousgoods" title={formatMessage(messages.dangerousGoods)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="skull-crossbones" size="2x" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.dangerousGoods} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button color="info" component={NavLink} exact
                                                            to="/factorsets/calendar" title={formatMessage(messages.calendar)}
                                                        >
                                                            <MenuItem onClick={popupState.close}>
                                                                <Grid container direction="column" alignItems="center">
                                                                    <Grid item>
                                                                        <FontAwesomeIcon icon="calendar-alt" size="2x" transform="shrink-2" />
                                                                    </Grid>
                                                                    <Typography variant="caption" textAlign="center" className={classes.typography}>
                                                                        <FormattedMessage {...messages.calendar} /></Typography>
                                                                </Grid>
                                                            </MenuItem>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Menu>
                                        </React.Fragment>
                                    )}
                                </PopupState>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption" className={classes.typography}><FormattedMessage {...messages.factorSets} /></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={classes.items}>
                    <span className={classes.puzzle} />
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls="account-menu"
                        aria-haspopup="true"
                        color="secondary"
                        className={clsx(classes.menuButtons, classes.accountIcon)}
                        onClick={_handleClick}
                        title={`${user.name} - ${user.roles.map(role => role.name).join(', ')}`}
                    >
                        <FontAwesomeIcon icon="user-circle" size="1x" className={classes.account} fixedWidth />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={_handleClose}
                    >
                        <MenuItem
                            onClick={() => dispatch(userLogout())}
                        >
                            <FontAwesomeIcon className={classes.marginRight} icon="sign-out-alt" size="1x" fixedWidth />
                            <FormattedMessage {...messages.signOut} values={{ name: user.name }} />
                        </MenuItem>

                    </Menu>
                </div>
            </Toolbar >
        </AppBar >
    );
}